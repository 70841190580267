@tailwind base;
@tailwind components;
@tailwind utilities;

/* Light theme styles */
.light-theme {
    --bg-color: #FFFFFF;
    --text-color: black;
    --button-bg-color: gray-200;
    --button-text-color: black;
    --icon-color: black;
    --exercise-card-color: #FFFFFF;
    --textarea-bg-color:#f9f9f9;
}

/* Dark theme styles */
.dark-theme {
    --bg-color: #111827;
    --text-color: white;
    --button-bg-color: gray-700;
    --button-text-color: white;
    --icon-color: white;
    --exercise-card-color: #263953;
    --textarea-bg-color:#576582;
}

* {
    background-color: var(--bg-color);
    color: var(--text-color);
}